var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-add-stock","title":"Добавление товара на склад","ok-title":"Сохранить","cancel-title":"Отмена","cancel-variant":"outline-secondary"},on:{"ok":_vm.addStock}},[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-12"},[_c('label',{staticClass:"d-inline d-lg-none"},[_vm._v("Товар")]),_c('v-select',{staticClass:"item-selector-title",attrs:{"options":_vm.products,"filterable":false,"label":"product_id","placeholder":"Выберите товар"},on:{"search":_vm.searchProduct},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var size_name = ref.size_name;
var product = ref.product;
return [_vm._v(" "+_vm._s(product.sku)+" - Размер "+_vm._s(size_name)+" ")]}},{key:"option",fn:function(ref){
var size_name = ref.size_name;
var qty = ref.qty;
var condition_name = ref.condition_name;
var product = ref.product;
return [_vm._v(" "+_vm._s(product.sku)+" - Размер "+_vm._s(size_name)+" - "+_vm._s(condition_name)),_c('br'),_c('small',[_vm._v(_vm._s(product.name)+" "+_vm._s(product.brand_name))]),_c('br')]}}]),model:{value:(_vm.form.specification),callback:function ($$v) {_vm.$set(_vm.form, "specification", $$v)},expression:"form.specification"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('label',{staticClass:"title"},[_vm._v("Розничная цена")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.price),expression:"form.price"}],staticClass:"form-control",domProps:{"value":(_vm.form.price)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "price", $event.target.value)}}})]),_c('div',{staticClass:"col-4"},[_c('label',{staticClass:"title"},[_vm._v("Валюта")]),(_vm.currencies.length > 0)?_c('v-select',{attrs:{"options":_vm.currencies,"disabled":_vm.currencies.length === 1,"label":"name"},model:{value:(_vm.form.currency),callback:function ($$v) {_vm.$set(_vm.form, "currency", $$v)},expression:"form.currency"}}):_vm._e()],1),_c('div',{staticClass:"col-4"},[_c('label',{staticClass:"title"},[_vm._v("Кол-во")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.qty),expression:"form.qty"}],staticClass:"form-control",domProps:{"value":(_vm.form.qty)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "qty", $event.target.value)}}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }