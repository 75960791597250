<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <div class="card">
            <div class="mx-2 my-2">
              <div class="row">
                <div class="col-12">
                  <button v-b-modal.modal-add-stock class="btn btn-primary">Добавить</button>
                </div>
              </div>
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ stocks.from }} по {{ stocks.to }} из {{ stocks.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      @input="changePage"
                      :total-rows="stocks.total"
                      :per-page="stocks.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
            <div class="position-relative table-responsive">
              <table class="table b-table">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Товар</th>
                  <th>Кол-во</th>
                  <th>Розничная Цена</th>
                  <th>Дата изменения</th>
                  <th>Действия</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="item in stocks.data">
                  <tr :key="item.id">
                    <td class="font-weight-bold">#{{ item.specification_id }}</td>
                    <td>
                      {{ item.specification.product.name }}
                      {{ item.specification.product.brand_name }}
                      ({{ item.specification.product.sku }})
                    </td>
                    <td>{{ item.qty }}</td>
                    <td>
                      <span class="amount positive">{{ item.price | toPretty }}</span>
                      <span class="currency">{{ item.currency.name }}</span>
                    </td>
                    <td>{{ item.updated_at | formattedDate }}</td>
                    <td>
                      <button class="btn btn-danger" @click="remove(item.id)">Удалить</button>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
            <div class="mx-2 mb-2">
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ stocks.from }} по {{ stocks.to }} из {{ stocks.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      @input="changePage"
                      :total-rows="stocks.total"
                      :per-page="stocks.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <AddStockModal @refresh="getStocks" />
  </div>
</template>

<script>
import { BPagination } from 'bootstrap-vue';
import AddStockModal from '@/views/stocks/AddStockModal.vue';

export default {
  name: 'Stocks',
  components: { AddStockModal, BPagination },
  data() {
    return {
      currentPage: 1,
      stocks: {
        current_page: 1,
        data: [],
        from: null,
        to: null,
      },
    };
  },
  async mounted() {
    await this.getStocks();
    if (this.$route.query.page) {
      this.currentPage = this.$route.query.page;
    }
  },
  methods: {
    async changePage(page) {
      this.stocks.current_page = page;
      await this.setFilters();
    },
    async setFilters() {
      this.loadFilters = true;
      const query = {
        ...(this.stocks.current_page > 1 && { page: this.stocks.current_page }),
      };

      await this.$router.replace({ query }).catch(() => {});
      await this.getStocks();
      this.loadFilters = false;
    },
    async getStocks() {
      const filters = { ...this.$route.query };
      filters.limit = 25;
      this.stocks = (await this.$api.productSpecifications.stocks(filters)).data;
    },
    async remove(userStockId) {
      try {
        await this.$api.userStock.delete(userStockId);
        await this.getStocks();
      } catch (e) {
        this.$bvToast.toast(`Ошибка при удалении товара со склада. ${e.response.data.message}`, {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.per-page-selector {
  width: 90px;
}

span.amount {
  font-size: 18px;
}
.positive {
  color: $success;
}
.negative {
  color: $danger;
}
span.currency {
  font-size: 10px;
}

.discount {
  text-transform: uppercase;
  &.warning {
    color: $warning;
    text-transform: uppercase;
  }
  &.danger {
    color: $danger;
  }
}
</style>
