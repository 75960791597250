<template>
  <b-modal
      id="modal-add-stock"
      title="Добавление товара на склад"
      ok-title="Сохранить"
      @ok="addStock"
      cancel-title="Отмена"
      cancel-variant="outline-secondary"
  >
    <div class="row mb-2">
      <div class="col-12">
        <label class="d-inline d-lg-none">Товар</label>
        <v-select
            v-model="form.specification"
            :options="products"
            :filterable="false"
            label="product_id"
            class="item-selector-title"
            placeholder="Выберите товар"
            @search="searchProduct"
        >
          <template #selected-option="{ size_name, product }">
            {{ product.sku }} - Размер {{ size_name }}
          </template>
          <template #option="{ size_name, qty, condition_name, product }">
            {{ product.sku }} - Размер {{ size_name }} - {{ condition_name }}<br>
            <small>{{ product.name }} {{ product.brand_name }}</small><br>
          </template>
        </v-select>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <label class="title">Розничная цена</label>
        <input class="form-control" v-model="form.price">
      </div>
      <div class="col-4">
        <label class="title">Валюта</label>
        <v-select
            v-if="currencies.length > 0"
            v-model="form.currency"
            :options="currencies"
            :disabled="currencies.length === 1"
            label="name"
        >
        </v-select>
      </div>
      <div class="col-4">
        <label class="title">Кол-во</label>
        <input class="form-control" v-model="form.qty">
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { toInteger } from '@/helpers/formatters';

export default {
  name: 'AddStockModal',
  components: { BModal, vSelect },
  data() {
    return {
      form: {
        specification: null,
        currency: null,
        price: null,
        qty: null,
      },
      products: [],
      currencies: [],
    };
  },
  async mounted() {
    await this.getCurrencies();
  },
  methods: {
    async getCurrencies() {
      this.currencies = (await this.$api.currencies.list()).data;
      this.currencies = this.currencies.filter((currency) => currency.id === 2);
      this.form.currency = this.currencies.find((currency) => currency.id === 2);
    },
    async searchProduct(query, loading) {
      if (query.length) {
        loading(true);
        this.$api.products.searchAll({ search: query }).then((response) => {
          this.products = response.data;
          loading(false);
        });
      }
    },
    async addStock() {
      if (!this.form.specification || !this.form.price || !this.form.qty) {
        this.$bvToast.toast('Выберите товар', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
        return;
      }

      try {
        await this.$api.userStock.create({
          specification_id: this.form.specification.id,
          price: toInteger(this.form.price),
          currency_id: this.form.currency.id,
          qty: this.form.qty,
        }).then(() => {
          this.$bvToast.toast('Товар успешно добавлен на склад', {
            title: 'Успех',
            variant: 'success',
            solid: true,
          });
          this.$emit('refresh');
        });
      } catch (e) {
        this.$bvToast.toast(e.response.data.message, {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
